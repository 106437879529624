<template>
  <div class="trouble-shoot">
    <trailBack></trailBack>
  </div>
</template>

<script>
import trailBack from '../devices/trail-back.vue'
export default {
  name: 'troubleShoot',
  components: {
    trailBack,
  },
  data() {
    return {}
  },
}
</script>
